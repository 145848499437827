import React, { useEffect, Fragment } from "react"
import CTA from "../general/cta"
import PhoneBlur from "../../../../assets/img/circle.inline.svg"
import HomeHeaderFold from "./sections/homeHeaderFold"
import AnimationTextLeft from "../general/animationTextLeft"
import AnimationTextRight from "../general/animationTextRight"
import { scrollToElement } from "../../../utility/utils"
import SendIcon from "../../../../assets/img/global/send.inline.svg"
import InvestIcon from "../../../../assets/img/global/invest.inline.svg"
import HelpIcon from "../../../../assets/img/global/help.inline.svg"

const entryContent = {
  title: (
    <span>
      The money app <br />
      for Africans.{" "}
    </span>
  ),
  subtitle: (
    <span>
      Save, spend, send and invest money
      <br /> across borders.
    </span>
  ),
}


const Global = () => {
  const phoneDebit = {
    title: <span>Save</span>,
    subtitle:
      "Putting money away for the future is smart but it takes discipline - something humans don’t have in abundance. We’re using technology to solve that by automating saving. You set an amount to save and the Kuda app takes care of the rest, prompt interest payout included.",
    buttonName: "Open An Account in Minutes",
    buttonUrl: "/joinKuda/",
    animation: "simpler-life",
    className: "transform",
  }

  const help = {
    title: "We’re always happy to help you.",
    subtitle:
      "You can chat with us on the app, slide into our DMs, tweet, leave an Instagram comment, send an email or call. However you choose to reach out, there’ll always be a friendly person there to make your life easy.",
    buttonName: "Contact Us",
    buttonUrl: "/contact",
    animation: "help",
  }

  const spend = {
    title: "Spend",
    subtitle:
      "Kuda gives you more than one way to pay easily, including a widely accepted debit card issued in partnership with Visa, quick web payments direct from your Kuda account, and location-based gift cards for shopping and subscriptions.",
    buttonName: "See all our Savings",
    buttonUrl: "/save",
    animation: "spend-money",
    className: "transform-right",
  }

  const send = {
    title: "Send",
    subtitle: (
      <span>
        From international transfers to on-the-spot payments at market stalls,
        we’ve simplified sending money so you don’t need to think too much about
        it. <br />
        <br />
        You can find your friends on Kuda and make unlimited instant transfers
        free of charge, send money to local banks without breaking a sweat, or
        fulfil your promises to family across borders.
      </span>
    ),

    animation: "send-money",
    className: "transform",
  }

  const invest = {
    title: "Invest",
    subtitle: (
      <span>
        We’re bringing verified global investment options closer to every
        African with fractional shares on Kuda, giving anyone with a Kuda
        account the opportunity to grow their money wherever they are in the
        world.
      </span>
    ),
    buttonName: "See all our Savings",
    buttonUrl: "/save",
    animation: "invest-money",
    className: "transform-right",
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <HomeHeaderFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        className="fade-active"
      />
      <AnimationTextLeft
        title={phoneDebit.title}
        subtitle={phoneDebit.subtitle}
        // buttonName={phoneDebit.buttonName}
        buttonUrl={phoneDebit.buttonUrl}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={phoneDebit.animation}
        leftClassName={phoneDebit.className}
        // image={<SaveIcon />}
      />
      <AnimationTextRight
        title={spend.title}
        subtitle={spend.subtitle}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={spend.animation}
        rightClassName={spend.className}
        // image={<SpendIcon />}
      />
      <AnimationTextLeft
        title={send.title}
        subtitle={send.subtitle}
        buttonName={send.buttonName}
        buttonUrl={send.buttonUrl}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={send.animation}
        image={<SendIcon />}
        leftClassName={send.className}
      />
      <AnimationTextRight
        title={invest.title}
        subtitle={invest.subtitle}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={invest.animation}
        image={<InvestIcon />}
        rightClassName={invest.className}
      />
      <AnimationTextLeft
        title={help.title}
        subtitle={help.subtitle}
        buttonName={help.buttonName}
        buttonUrl={help.buttonUrl}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={help.animation}
        isExternal={true}
        image={<HelpIcon />}
      />

      <CTA />
    </Fragment>
  )
}

export default Global
